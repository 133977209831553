import React, { FC, PropsWithChildren } from 'react'
import styles from './Login.module.scss'

export interface AuthContainerProps {
  showError?: boolean | undefined;
}

export const AuthContainer: FC<PropsWithChildren<AuthContainerProps>> = (props) => (
  <main className={styles.outerAuthContainer}>
    <div className={props.showError ? `${styles.innerAuthContainer} ${styles.showError}` : `${styles.innerAuthContainer}`}>
      {props.children}
    </div>
  </main>
)