import React, {
  ButtonHTMLAttributes,
  ComponentClass,
  createElement,
  forwardRef,
  FunctionComponent, LegacyRef,
  // useEffect,
  // useRef,
} from 'react'
import classnames from 'classnames'
import { El, ElProps } from './El'
import styles from './Button.module.scss'
// import { useMergeRefs } from './useMergeRefs'

type BaseProps = ElProps & {
  variant?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'ghost'
  | 'unstyled'
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  active?: boolean
  focus?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  disableSvgStyles?: boolean
}

export type ButtonProps = BaseProps & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, propsRef) => {
    const {
      className,
      variant = 'primary',
      size = 'md',
      active,
      focus,
      leftIcon,
      rightIcon,
      disableSvgStyles,
      children,
      ...rest
    } = props

    // TODO: figure out best way to migrate useEffect hook to something else in server component
    // const innerRef = useRef<HTMLButtonElement>(null)
    // const ref = useMergeRefs(innerRef, propsRef)
    // useEffect(() => {
    //   const blur = () => innerRef.current?.blur()
    //   innerRef.current?.addEventListener('click', blur)
    //   // copy innerRef.current to local variable per ESLint rule (ref may be cleaned up by the time the effect runs)
    //   const observerRef = innerRef.current
    //   return () => {
    //     observerRef?.removeEventListener('click', blur)
    //   }
    // }, [innerRef])

    const c = classnames(
      styles.base,
      styles[variant],
      styles[size],
      active ? styles.active : undefined,
      focus ? styles.focus : undefined,
      leftIcon ? styles['left-icon'] : undefined,
      rightIcon ? styles['right-icon'] : undefined,
      disableSvgStyles ? styles['disable-svg-styles'] : undefined,
      className
    )

    return (
      <El ref={propsRef} as="button" className={c} {...rest}>
        {leftIcon ?? null}
        {children}
        {rightIcon ?? null}
      </El>
    )
  }
)
Button.displayName = 'Button'

// export type LinkButtonProps = BaseProps &
//   AnchorHTMLAttributes<HTMLAnchorElement>
// // RouterLinkProps

export type RouterLinkButtonProps<AsType extends object> = Omit<
  BaseProps,
  'as'
> &
Omit<AsType, 'as'> & {
  as: FunctionComponent<AsType> | ComponentClass<AsType>
}

interface RouterLinkWithForwardRef
  extends React.FC<
    RouterLinkButtonProps<object> & { ref?: LegacyRef<HTMLAnchorElement> }
  > {
  <AsType extends object>(props: RouterLinkButtonProps<AsType>): ReturnType<
    React.FC<RouterLinkButtonProps<AsType>>
  >
}

// TODO: keyboard/tab navigation doesn't seem to be working for LinkButton like Button
/**
 * A styled ui/Button that wraps react-router-dom's Link component. Looks like our ui/Button but renders an <a> tag.
 */
export const RouterLinkButton: RouterLinkWithForwardRef = forwardRef(
  (props, ref) => {
    const {
      as,
      className,
      variant = 'primary',
      size = 'md',
      active,
      focus,
      leftIcon,
      rightIcon,
      children,
      ...rest
    } = props

    const c = classnames(
      styles.base,
      styles[variant],
      styles[size],
      active ? styles.active : undefined,
      focus ? styles.focus : undefined,
      leftIcon ? styles['left-icon'] : undefined,
      rightIcon ? styles['right-icon'] : undefined,
      className
    )

    return createElement(
      as,
      // @ts-ignore TODO: fix ref type
      { ...rest, ref, className: c },
      <>
        {leftIcon ?? null}
        {children}
        {rightIcon ?? null}
      </>,
    )
  }
)

RouterLinkButton.displayName = 'RouterLinkButton'
