'use client'

import { buildSafeUrl } from '@/utils/misc'
// React-specific imports
import React, { ButtonHTMLAttributes, FC, PropsWithChildren, useActionState, useEffect } from 'react'

// Next.js-specific imports
import Image from 'next/image'

// Dependencies
import { useTranslations } from 'next-intl'

// Components
import AlgebraTilesIcon from '@/public/algebra-tiles.svg'
import BarModelIcon from '@/public/bar-model.svg'
import BaseTenBlocksIcon from '@/public/base-10-blocks.svg'
import DesmosGeometryIcon from '@/public/desmos-geometry.svg'
import DesmosGraphingIcon from '@/public/desmos-graphing.svg'
import DesmosScientificIcon from '@/public/desmos-scientific.svg'
import FractionStripsIcon from '@/public/fraction-strips.svg'
import IntegerChipsIcon from '@/public/integer-chips.svg'
import PatternBlocksIcon from '@/public/pattern-blocks.svg'
import SpinnerWheelIcon from '@/public/spinner-wheel.svg'
import WhiteboardIcon from '@/public/whiteboard.svg'
import { MathToolsIconText } from '@/components/Home/MathToolsIconText'

// Actions
import { setRecentlyViewed } from '@/actions/user/setRecentlyViewed'
import { openWhiteboard } from '@/actions/interactive-tools/openWhiteboard'

// Hooks
import useWhiteboardSession from '@/hooks/useWhiteboardSession'

// Constants
import {
  ALGEBRA_TILES,
  BAR_MODEL,
  BASE_TEN_BLOCKS,
  DESMOS_GEOMETRY_TOOLS,
  DESMOS_GRAPHING_CALCULATOR,
  DESMOS_SCIENTIFIC_CALCULATOR,
  FRACTION_STRIPS,
  INTEGER_CHIPS, MATH_TOOL_POPOUT_HEIGHT, MATH_TOOL_POPOUT_WIDTH,
  MathToolsEnum,
  PATTERN_BLOCKS,
  RECENTLY_VIEWED_MATHTOOL,
  SPINNER,
} from '@/constants'

// Styles
import styles from './MathToolsIcon.module.scss'

interface Props {
  mathToolName: string
  mathToolsUrl?: string
}

export const MathToolsIcon: React.FC<Props> = ({
  mathToolName,
  mathToolsUrl,
}: Props) => {
  const t = useTranslations('tools')

  const [openWhiteboardState, startOpenWhiteboard] = useActionState(openWhiteboard, undefined)

  // handle recently viewed whiteboard update
  useEffect(() => {
    const completeOpenWhiteboard = async () => {
      if (openWhiteboardState != null) {
        await setRecentlyViewed({
          id: mathToolName,
          viewedType: RECENTLY_VIEWED_MATHTOOL,
        })
      }
    }
    completeOpenWhiteboard()
  }, [openWhiteboardState])

  useWhiteboardSession({ ctx: openWhiteboardState, mode: 'popup' })

  const openMathtool = async (type: string) => {
    await setRecentlyViewed({
      id: mathToolName,
      viewedType: RECENTLY_VIEWED_MATHTOOL,
    })

    if (mathToolsUrl) {
      window.open(buildSafeUrl(mathToolsUrl, `${type}?isPopout=true`), 'mathtool', `width=${MATH_TOOL_POPOUT_WIDTH},height=${MATH_TOOL_POPOUT_HEIGHT}`)
    } else {
      console.error('mathToolsUrl is undefined')
    }
  }

  const renderContent = React.useCallback(() => {
    switch (mathToolName) {
      case MathToolsEnum.PATTERN_BLOCKS:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(PATTERN_BLOCKS)}
            data-test="math-tools-pattern-blocks"
          >
            <Image data-test="mathTools-PatternBlocksIcon-image" src={PatternBlocksIcon} alt="" />
            <MathToolsIconText>
              {t('patternBlocks.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.SPINNER:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(SPINNER)}
            data-test="math-tools-spinner-wheel"
          >
            <Image data-test="mathTools-SpinnerWheelIcon-image" src={SpinnerWheelIcon} alt="" />
            <MathToolsIconText>
              {t('spinner.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.INTEGER_CHIPS:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(INTEGER_CHIPS)}
            data-test="math-tools-integer-chips"
          >
            <Image data-test="mathTools-IntegerChipsIcon-image" src={IntegerChipsIcon} alt="" />
            <MathToolsIconText>
              {t('integerChips.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.BAR_MODEL:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(BAR_MODEL)}
            data-test="math-tools-bar-model"
          >
            <Image data-test="mathTools-BarModelIcon-image" src={BarModelIcon} alt="" />
            <MathToolsIconText>
              {t('barModel.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.SCIENTIFIC_CALCULATOR:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(DESMOS_SCIENTIFIC_CALCULATOR)}
            data-test="math-tools-scientific-calculator"
          >
            <Image data-test="mathTools-DesmosScientificIcon-image" src={DesmosScientificIcon} alt="" />
            <MathToolsIconText>
              {t('scientificCalculator.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.GRAPHING_CALCULATOR:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(DESMOS_GRAPHING_CALCULATOR)}
            data-test="math-tools-graphing-calculator"
          >
            <Image data-test="mathTools-DesmosGraphingIcon-image" src={DesmosGraphingIcon} alt="" />
            <MathToolsIconText>
              {t('graphingCalculator.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.BASE_TEN_BLOCKS:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(BASE_TEN_BLOCKS)}
            data-test="math-tools-base-ten-blocks"
          >
            <Image data-test="mathTools-BaseTenBlocksIcon-image" src={BaseTenBlocksIcon} alt="" />
            <MathToolsIconText>
              {t('baseTenBlocks.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.GEOMETRY_TOOLS:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(DESMOS_GEOMETRY_TOOLS)}
            data-test="math-tools-geometry-tools"
          >
            <Image data-test="mathTools-DesmosGeometryIcon-image" src={DesmosGeometryIcon} alt="" />
            <MathToolsIconText>
              {t('geometryTools.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.ALGEBRA_TILES:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(ALGEBRA_TILES)}
            data-test="math-tools-algebra-tiles"
          >
            <Image data-test="mathTools-AlgebraTilesIcon-image" src={AlgebraTilesIcon} alt="" />
            <MathToolsIconText>
              {t('algebraTiles.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.FRACTION_STRIPS:
        return (
          <MathToolsIconContainer
            onClick={() => openMathtool(FRACTION_STRIPS)}
            data-test="math-tools-fraction-strips"
          >
            <Image data-test="mathTools-FractionStripsIcon-image" src={FractionStripsIcon} alt="" />
            <MathToolsIconText>
              {t('fractionStrips.title')}
            </MathToolsIconText>
          </MathToolsIconContainer>
        )

      case MathToolsEnum.WHITEBOARD:
        return (
          <form>
            <MathToolsIconContainer
              formAction={startOpenWhiteboard}
              data-test="interactive-tools-whiteboard"
            >
              <Image data-test="mathTools-WhiteboardIcon-image" src={WhiteboardIcon} alt="" />
              <MathToolsIconText>
                {t('whiteboard.title')}
              </MathToolsIconText>
            </MathToolsIconContainer>
          </form>
        )

      default:
        return null
    }
  }, [mathToolName])

  return renderContent()
}

const MathToolsIconContainer: FC<PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  children,
  ...rest
}) => (
  <button data-test="math-tools-icon-button" className={styles.MathToolsIconButton} {...rest}>{children}</button>
)
