'use client'

import { useEffect } from 'react'
import { type ApmBase } from '@elastic/apm-rum'
import { useImmer } from 'use-immer'

import { type UserData } from '@/components/Elastic/ElasticInstrumentation'

export interface ElasticUserSessionMonitorProps {
  apm?: ApmBase
  userData?: UserData
}

function hasTrackedUserData(data: Partial<UserData>) {
  return data.userId != null && data.username != null && data.email != null
}

export default function ElasticUserSessionMonitor(props: ElasticUserSessionMonitorProps) {
  const { apm, userData } = props

  // use immutable state tracking for user data
  const [trackedUserData, setTrackedUserData] = useImmer<Partial<UserData>>({
    userId: userData?.userId,
    username: userData?.username,
    email: userData?.email,
  })

  useEffect(() => {
    setTrackedUserData((draft) => {
      if (draft == null) {
        // create draft state
        draft = {
          userId: userData?.userId,
          username: userData?.username,
          email: userData?.email,
        }
        return draft
      }

      // update draft state
      draft.userId = userData?.userId
      draft.username = userData?.username
      draft.email = userData?.email
    })
  }, [userData])

  useEffect(() => {
    if (apm != null && hasTrackedUserData(trackedUserData)) {
      apm.setUserContext({
        id: trackedUserData.userId,
        username: trackedUserData.username,
        email: trackedUserData.email,
      })
    }
  }, [apm, trackedUserData])

  return null
}