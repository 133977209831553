'use client'

// React-specific imports
import React, { useEffect, useState } from 'react'

// Next.js-specific imports
import { useRouter } from 'next/navigation'

// Dependencies
import { useForm } from 'react-hook-form'
import { useTranslations } from 'next-intl'

// Edwin UI-specific imports
import { Flex } from '@edwin-edu/ui/server'
import { Spinner } from '@edwin-edu/ui/server'
import { Button } from '@edwin-edu/ui/server'
import { Text } from '@edwin-edu/ui/server'
import { Welcome } from '@edwin-edu/ui/client'
import { emailRegex } from '@edwin-edu/ui/server'

// Components
import { AuthContainer } from '@/components/Login/AuthContainer'

// Constants

// Styles
import styles from './Login.module.scss'

export interface LoginProps {
  onEmailLogin: (name: boolean) => void
  onProviderLogin: (display: string) => void
  onSubmit: (data: { email: string }) => void
  showSpinner?: boolean
  showEmailField?: boolean
  mode?: string
  footer?: React.ReactNode
  locale?: string
  joinUrl: string
}

export const Login: React.FC<LoginProps> = ({
  onEmailLogin,
  onProviderLogin,
  onSubmit,
  showSpinner,
  showEmailField,
  mode,
  footer,
  locale,
  joinUrl,
}: LoginProps) => {
  const t = useTranslations('login')
  const [didSubmitEmail, setDidSubmitEmail] = useState(true)
  const [displayContinue, setDisplayContinue] = useState(false)
  const [email, setEmail] = useState('')

  const router = useRouter()

  const {
    watch,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>()

  const invalidEmail = (email && !emailRegex.test(email)) || false
  useEffect(() => {
    clearErrors()
    setDidSubmitEmail(false)
    if (email && email?.length) {
      setDisplayContinue(true)
    } else {
      console.warn('provided email is not well-formed')
      setDisplayContinue(false)
    }
  }, [email])

  useEffect(() => {
    clearErrors()
    setDidSubmitEmail(false)
  }, [])

  const showSpinnerAndLoginWith = async (
    providerName: string
  ): Promise<void> => {
    onProviderLogin(providerName)
  }

  const displayEmailFieldContainer = async (
    displayEmailField: boolean
  ): Promise<void> => {
    onEmailLogin(displayEmailField)
  }

  const studentMode = mode === 'student'
  const loginAction = studentMode
    ? t('actionJoin')
    : t('actionSignIn')

  return (
    <AuthContainer>
      <div>
        {!studentMode && <Welcome label={t('welcomeToEdwin')} />}
        <div className={styles.signInInfo}>
          {showSpinner ? (
            <>
              <Spinner data-test="login-modal-spinner" />
              <div
                className={styles.spinnerText}
                data-test="login-modal-auth-text"
              >
                {t('authenticating')}
              </div>
            </>
          ) : !studentMode ? (
            <div className={styles.welcomeDesc}>
              {mode === 'document' ? t('signInLongDocs') : t('signInPortal')}
            </div>
          ) : (
            <Text variant="large" fontWeight={700}>
              {t('studentJoin')}
            </Text>
          )}
        </div>
      </div>
      <Flex direction="column" justifyContent="center" gap={4} my={3}>
        <Button
          type="button"
          onClick={() => showSpinnerAndLoginWith('google')}
          name={`${loginAction} Google`}
          data-test="login-form-google-button"
          disableSvgStyles={true}
        >
          {GoogleIcon}
          <Text data-test="login-modal-google-text" fontWeight={700} ml={2}>
            {`${loginAction} Google`}
          </Text>
        </Button>
        <Button
          type="button"
          onClick={() => showSpinnerAndLoginWith('microsoft')}
          name={`${loginAction} Microsoft`}
          data-test="login-form-microsoft-button"
          disableSvgStyles={true}
        >
          {MicrosoftIcon}
          <Text data-test="login-modal-msft-text" fontWeight={700} ml={2}>
            {`${loginAction} Microsoft`}
          </Text>
        </Button>
      </Flex>

      <form
        className={styles.bottomRow}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit)()
        }}
      >
        <Flex alignItems="center">
          <div className={styles.orRow} />
          <span
            className={styles.orRowText}
            data-test="login-modal-hr-line-or"
          >
            {t('or')}
          </span>
          <div className={styles.orRow} />
        </Flex>

        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
          my={3}
        >
          {showEmailField ? (
            <div className={styles.displayEmailFieldContainer}>
              <input
                className={styles.authInput}
                aria-label={t('email')}
                {...register('email', {
                  required: t('email'),
                  pattern: emailRegex,
                })}
                placeholder={t('email')}
                data-test="login-modal-manual-email-input"
                onChange={(event) => {
                  const email = event.target.value.trim()
                  setEmail(email)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onSubmit({ email })
                  }
                }}
              />

              {invalidEmail && !errors.email && (
                <div className={styles.invalidEmail}>
                  {t('error.invalidEmailFormat')}
                </div>
              )}
              {errors.email && errors.email.message}

              {didSubmitEmail ? (
                <>
                  <Spinner data-test="login-modal-submit-spinner" />
                  <div className={styles.spinnerText}>
                    {t('authenticating')}
                  </div>
                </>
              ) : displayContinue ? (
                <Button
                  className={styles.continueButton}
                  type="submit"
                  disabled={invalidEmail}
                  data-test="login-modal-continue-button"
                >
                  {t('continue')}
                </Button>
              ) : (
                <div className={styles.margin} />
              )}
            </div>
          ) : (
            <Button
              type="button"
              className={styles.signInWithEmail}
              aria-label={t('signInDifferentEmail')}
              variant="ghost"
              mt={1}
              mb={2}
              onClick={() => displayEmailFieldContainer(true)}
              data-test="sign-in-with-different-email-button"
            >
              {loginAction} {t('differentEmail')}
            </Button>
          )}

          {studentMode ? (
            <Text color="primary" mb={6} textAlign="center">
              <>{t('studentUse')}</>
            </Text>
          ) : (
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                router.push(joinUrl)
              }}
              variant="secondary"
              data-test="login-student-join-button"
            >
              {t('studentSignUp')}{' '}
              {t('studentSignUp2')}
            </Button>
          )}

          <a
            className={styles.helpLink}
            href="https://help.edwin.app/s/article/Sign-in-to-Edwin"
            target="_blank"
            data-test="login-modal-help-link"
          >
            {t('needHelp')}
          </a>
        </Flex>

        {footer}
      </form>
    </AuthContainer>
  )
}

const GoogleIcon = (
  <svg
    viewBox="0 0 533.5 544.3"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
  >
    <path
      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
      fill="#4285f4"
    />
    <path
      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
      fill="#34a853"
    />
    <path
      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
      fill="#fbbc04"
    />
    <path
      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
      fill="#ea4335"
    />
  </svg>
)

const MicrosoftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="18"
    height="18"
  >
    <path fill="#f3f3f3" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </svg>
)
