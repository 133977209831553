'use client'

import { type ReactNode } from 'react'
import { ErrorBoundary, type ErrorBoundaryPropsWithFallback } from 'react-error-boundary'
import { ApmConfig } from './ElasticInstrumentation'

import { useElasticApm } from '@/hooks/useElasticApm'

export interface ElasticClientErrorBoundaryProps {
  apmConfig: ApmConfig
  fallback: ErrorBoundaryPropsWithFallback['fallback']
  children: ReactNode
}

export default function ElasticClientErrorBoundary (props: ElasticClientErrorBoundaryProps) {
  const { apmConfig, fallback, children } = props

  const apm = useElasticApm(apmConfig)

  const logger: ErrorBoundaryPropsWithFallback['onError'] = (error, info) => {
    if (apm != null) {
      apm.captureError(error)
    }
  }

  return (
    <ErrorBoundary fallback={fallback} onError={logger}>
      {children}
    </ErrorBoundary>
  )
}