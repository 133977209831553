'use client'

// React-specific imports
import React from 'react'

// Dependencies
import { useTranslations } from 'next-intl'

// Edwin UI-specific imports
import { Button } from '@edwin-edu/ui/server'

// Components
import { AuthContainer } from '@/components/Login/AuthContainer'

// Styles
import styles from './Login.module.scss'

export interface ErrorModalProps {
  onTryAgain: () => void
  inactiveUser: boolean | null | undefined
  inactiveLicense: boolean | null | undefined
  unknownError: boolean | null | undefined
}

export const LoginError: React.FC<ErrorModalProps> = ({
  onTryAgain,
  inactiveUser,
  inactiveLicense,
  unknownError
}) => {
  const t = useTranslations('login')

  return (
    <AuthContainer showError>
      <div className={styles.errorContainer}>

        {!unknownError && (<>
          <div className={styles.errorNumber} data-test="error-modal-error-number">
            {inactiveUser ? '302' : inactiveLicense ? '381' : '300'}
          </div>

          <h1 className={styles.errorTitle} data-test="error-modal-title">
            {inactiveUser ? t('error.licenseExpired') : inactiveLicense ? t('error.inactiveLicense') : t('error.accountNotRecognized')}
          </h1>

          <p className={styles.errorDesc} data-test="error-modal-description">
            {inactiveUser ? t('error.infoLicenseExpired') : inactiveLicense ? t('error.infoLicenseInactive') : t('error.infoNotRecognized')}
          </p>
        </>)}

        {unknownError && (<>
          <div className={styles.errorNumber} data-test="error-modal-error-number">
            382
          </div>

          <h1 className={styles.errorTitle} data-test="error-modal-title">
            {t('error.unknown')}
          </h1>

          <p className={styles.errorDesc} data-test="error-modal-description">
            {t('error.infoUnknown')}
          </p>
        </>)}

        {!inactiveUser && !inactiveLicense && (
          <p className={styles.errorSubDesc} data-test="error-modal-contact-info">
            {t('error.contactInfo')}
            <a
              className={styles.contact}
              data-test="error-modal-contact-help-link"
              href="https://help.edwin.app/s/article/Solution-for-Error-300"
            >
              {t('error.contactUs')}
            </a>
          </p>
        )}

        <Button
          className={styles.tryAgainButton}
          data-test="error-modal-try-again-button"
          onClick={onTryAgain}
        >
          {t('tryAgain')}
        </Button>

      </div>
    </AuthContainer>
  )
}
